<template>
<body>
  <div data-barba="wrapper">

    <NavBar />

    <!-- PAGE MAIN -->
    <div class="js-smooth-scroll bg-light-1" id="page-wrapper" data-barba="container">
      <main class="page-wrapper__content">
        <!-- section MASTHEAD -->
        <section class="section section-masthead pt-large pb-medium text-center" data-arts-os-animation="data-arts-os-animation" data-background-color="var(--color-light-1)">
          <div class="section-masthead__inner container-fluid">
            <header class="row section-masthead__header justify-content-center">
              <div class="col-lg-8">
                <div class="section-masthead__heading split-text js-split-text" data-split-text-type="lines,words" data-split-text-set="words">
                  <h1 class="mt-0 mb-0 h1">Blog</h1>
                </div>
                <div class="w-100"></div>
                <div class="section__headline mt-2 mx-auto"></div>
              </div>
            </header>
          </div>
        </section>
        <!-- - section MASTHEAD -->
        <!-- section BLOG LIST -->
        <section class="section section-blog pb-medium text-center">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-9 section-blog__posts">
                <!-- posts -->
                <div class="section-blog__wrapper-post" v-for="(post, index) in posts" :key="index">
                  <article class="figure-post">
                    <div class="figure-post__media">
                      <router-link :to="post.slug"><img :src="post.feature_image" style="max-height: 400px;" alt="" />

                      </router-link>
                    </div>
                    <div class="figure-post__wrapper-info mt-3 small-caps">
                      <ul class="post-meta">
                        <li>
                          <ul>
                            <li><a v-for="tag in post.tags" :key="tag.id">#{{tag.name}},</a></li>
                          </ul>
                        </li>
                        <li><a v-for="author in post.authors" :key="author.id">{{author.name}},</a></li>
                      </ul>
                    </div>
                    <div class="figure-post__header mt-1">
                      <router-link class="d-block" :to="post.slug">
                        <h2 class="h3 mt-0"> {{ post.title }} </h2>
                      </router-link>
                    </div>
                    <div class="figure-post__content">
                      <p>{{ post.excerpt | truncate(300,'...')}}</p>
                    </div>
                    <div class="figure-post__wrapper-readmore mt-1 mt-md-2 text-center">
                      <router-link class="button button_bordered button_black" data-hover="Read More" :to="post.slug"><span class="button__label-hover">Read More</span></router-link>
                    </div>

                    <div class="w-100"></div>
                    <div class="section__headline mt-2 mx-auto"></div>
                  </article>
                </div>

              </div>
            </div>
          </div>
        </section>
        <!-- - section BLOG LIST -->
      </main>
      <!-- PAGE FOOTER -->
      <footer class="footer container-fluid" id="page-footer" data-arts-theme-text="dark" data-arts-footer-logo="primary">

        <!-- widgets bottom area -->
        <div class="footer__area footer__area-border-top pt-sm-3 pb-sm-1 pt-2 pb-0">
          <div class="row align-items-center">
            <!-- widget LOGO -->
            <div class="col-lg-3 footer__column text-center text-lg-left order-lg-1">
              <section class="widget widget_rhye_logo">
              </section>
            </div>
            <!-- - widget LOGO -->
            <!-- widget SOCIAL -->
            <div class="col-lg-3 footer__column text-center text-lg-right order-lg-3">
              <section class="widget widget_rhye_social">
                <!-- content -->
                <ul class="social">
                  <li class="social__item"><a class="fa fa-facebook-f" href="https://www.facebook.com/iyawat" target="_blank"></a></li>
                  <li class="social__item"><a class="fa fa-twitter" href="https://twitter.com/iyawat" target="_blank"></a></li>
                  <li class="social__item"><a class="fa fa-linkedin" href="https://th.linkedin.com/in/iyawat-kongmalai-b7434729" target="_blank"></a></li>
                  <li class="social__item"><a class="fa fa-medium" href="https://medium.com/@iyawatkongmalai" target="_blank"></a></li>
                </ul>
                <!-- - content -->
              </section>
            </div>
            <!-- - widget SOCIAL -->
            <!-- widget TEXT -->
            <div class="col-lg-6 footer__column text-center text-lg-center order-lg-2">
              <section class="widget widget_text">
                <!-- content -->
                <div class="textwidget">
                  <p><small>© 2020 Iyawat Kongmalai</small></p>
                </div>
                <!-- - content -->
              </section>
            </div>
            <!-- - widget TEXT -->
          </div>
        </div>
        <!-- - widgetst bottom area -->
      </footer>
      <!-- - PAGE FOOTER -->
    </div>
    <!-- - PAGE MAIN -->

  </div>

</body>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar'
import {
  getAllTags,
  getPosts
} from '@/api/posts'

export default {
  name: 'Blog',
  data: () => ({
    tag: '',
    tags: [],
    posts: [],
    AllPosts: []
  }),
  components: {
    NavBar
  },
  async mounted() {
    await this.asyncData()
    if (this.$route.query.tag) {
      this.tag = this.$route.query.tag.toUpperCase()
    }
  },
  methods: {
    // async asyncData() {
    //   getPosts().then(result => {
    //     this.posts = result
    //     // console.log(result)
    //   })
    // }
    async asyncData() {
      getAllTags().then(result => {
        this.tags = result
      })
      getPosts().then(result => {
        this.AllPosts = result
        if (this.$route.query.tag == 'all' || !this.$route.query.tag || this.$route.query.tag == '') {
          this.posts = result
        } else {
          this.posts = result.filter(
            x => {
              for (let i = 0; i < x.tags.length; i++) {
                if (x.tags[i].name == this.$route.query.tag) {
                  return typeof x !== undefined
                }
              }
            }
          )
        }
      })
    }
  },
  watch: {},
  filters: {
    truncate: function (text, length, suffix) {
      if (text !== null) {
        return text.substring(0, length) + suffix
      }
    },
    formatDate: function (date) {
      return date.replace('T', ', ').replace('.000', '').replace('+07:00', '')
    }
  }
}
</script>
