<template>
<body>
  <div data-barba="wrapper">

    <NavBar />

    <!-- PAGE MAIN -->
    <div class="js-smooth-scroll bg-light-3" id="page-wrapper" data-barba="container">
      <main class="page-wrapper__content">
        <!-- section MASTHEAD -->
        <section class="section section-masthead section-fullheight text-center text-lg-right pt-large pt-md-0" data-arts-os-animation="data-arts-os-animation" data-background-color="var(--color-light-4)">
          <div class="section-masthead__inner section-fullheight__inner section-fullheight__inner_mobile-auto">
            <div class="row no-gutters h-100 align-items-center">
              <div class="col-lg-6 h-100">
                <div class="container-fluid container-fluid_paddings h-100 container_py-xs-0">
                  <div class="row align-items-center h-100">
                    <div class="col">
                      <header class="section-masthead__header justify-content-center">
                        <div class="section-masthead__subheading small-caps mt-0 mb-1 mb-md-2 split-text js-split-text" data-split-text-type="lines,words" data-split-text-set="words"><span>Technologist & Innovator</span></div>
                        <div class="w-100"></div>
                        <div class="section-masthead__heading split-text js-split-text" data-split-text-type="lines,words" data-split-text-set="words">
                          <h1 class="h1 mt-0 mb-0">I'm Iyawat Kongmalai</h1>
                        </div>
                      </header>
                    </div>
                  </div>
                  <div class="section-masthead__wrapper-scroll-down section-masthead__wrapper-scroll-down_right d-none d-lg-block">
                    <div class="circle-button js-circle-button" data-arts-os-animation="true">
                      <!-- curved label -->
                      <div class="circle-button__outer">
                        <div class="circle-button__wrapper-label">
                          <div class="circle-button__label small-caps">Scroll Down</div>
                        </div>
                      </div>
                      <!-- - curved label -->
                      <!-- geometry wrapper -->
                      <div class="circle-button__inner">
                        <div class="circle-button__circle" data-arts-scroll-down="data-arts-scroll-down" data-arts-cursor="data-arts-cursor" data-arts-cursor-hide-native="true" data-arts-cursor-scale="0">
                          <svg class="svg-circle" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <circle class="circle" cx="30" cy="30" r="29" fill="none"></circle>
                          </svg>
                        </div>
                        <!-- browsers with touch support -->
                        <div class="circle-button__icon circle-button__icon-touch">
                          <svg enable-background="new 0 0 64 64" height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg">
                            <path d="m32 8c-1.104 0-2 .896-2 2v39.899l-14.552-15.278c-.761-.799-2.026-.832-2.828-.069-.8.762-.831 2.027-.069 2.827l16.62 17.449c.756.756 1.76 1.172 2.829 1.172 1.068 0 2.073-.416 2.862-1.207l16.586-17.414c.762-.8.73-2.065-.069-2.827-.799-.763-2.065-.731-2.827.069l-14.552 15.342v-39.963c0-1.104-.896-2-2-2z"></path>
                          </svg>
                        </div>
                        <!-- - browsers with touch support -->
                        <!-- - browsers without touch support -->
                        <div class="circle-button__icon circle-button__icon-mouse">
                          <svg class="svg-mouse" width="23px" height="35px" viewBox="0 0 23 35" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <!-- border -->
                            <path class="svg-mouse__border" d="M11.5,0 C5.15875132,0 0,5.23135343 0,11.6610111 L0,23.3396542 C0,29.7691456 5.15875132,35 11.5,35 C17.8412487,35 23,29.7693119 23,23.3396542 L23,11.6610111 C23,5.23135343 17.8410847,0 11.5,0 Z M21.7222404,23.3396542 C21.7222404,29.0545544 17.136538,33.7037222 11.5,33.7037222 C5.86346203,33.7037222 1.27775956,29.0545544 1.27775956,23.3396542 L1.27775956,11.6610111 C1.27775956,5.946111 5.86346203,1.29627781 11.5,1.29627781 C17.136538,1.29627781 21.7222404,5.94594466 21.7222404,11.6610111 L21.7222404,23.3396542 Z"></path>
                            <!-- - border -->
                            <!-- wheel -->
                            <path class="svg-mouse__wheel" d="M11.5,4 C11.2238902,4 11,4.28321727 11,4.63321727 L11,10.3667827 C11,10.7167827 11.2238902,11 11.5,11 C11.7761098,11 12,10.7167827 12,10.3667827 L12,4.63321727 C11.9998717,4.28321727 11.7761098,4 11.5,4 Z"></path>
                            <!-- - wheel -->
                          </svg>
                        </div>
                        <!-- - browsers without touch support -->
                      </div>
                      <!-- - geometry wrapper -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 align-self-stretch">
                <div class="section-image section-masthead__background section-masthead__background_halfscreen section-masthead__background_halfscreen-gutters mt-small mt-md-0">
                  <div class="section-image__wrapper js-transition-img" data-arts-parallax="data-arts-parallax" data-arts-parallax-factor="0.15">
                    <div class="js-transition-img__transformed-el">
                      <div class="lazy-bg" data-src="img/assets/sectionAbout/profile-img.jpg"></div>
                    </div>
                  </div>
                  <div class="section-masthead__bg bg-white"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- - section MASTHEAD -->
        <!-- section CONTENT #1 -->
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <section class="section section-content clearfix pt-medium text-center" data-arts-os-animation="data-arts-os-animation">
                <div class="section-content__inner">
                  <div class="w-100"></div>
                  <div class="section__headline mb-1 mb-md-2 mx-auto"></div>
                  <div class="w-100"></div>
                  <div class="section-content__heading split-text js-split-text" data-split-text-type="lines,words" data-split-text-set="words">
                    <h3>Hi, My Name is Iyawat Kongmalai </h3>
                    <h5 style="font-weight: 500;">I’m a full-stack developer currently in Thailand. My interests are in cutting-edge technologies like Blockchain, AI, IoT and new technologies on web development. I also love doing traveling and food blogging</h5>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <!-- - section CONTENT #1 -->
        <!-- aside COUNTERS -->
        <aside class="aside aside-counters section pt-xsmall text-center">
          <div class="container">
            <div class="row justify-content-center">
              <!-- counter -->
              <div class="aside-counters__wrapper-item col-lg-3 col-sm-6">
                <div class="counter js-counter" data-counter-start="0" data-counter-target="20" data-counter-duration="4">
                  <!-- animated number -->
                  <div class="counter__number js-counter__number">20</div>
                  <!-- - animated number -->
                  <div class="counter__label">Years in Working</div>
                </div>
              </div>
              <!-- - counter -->
              <!-- counter -->
              <div class="aside-counters__wrapper-item col-lg-3 col-sm-6">
                <div class="counter js-counter" data-counter-start="0" data-counter-target="10" data-counter-duration="4">
                  <!-- animated number -->
                  <div class="counter__number js-counter__number">10</div>
                  <!-- - animated number -->
                  <div class="counter__label">Years in Software Development</div>
                </div>
              </div>
              <!-- - counter -->
              <!-- counter -->
              <div class="aside-counters__wrapper-item col-lg-3 col-sm-6">
                <div class="counter js-counter" data-counter-start="0" data-counter-target="5" data-counter-duration="4">
                  <!-- animated number -->
                  <div class="counter__number js-counter__number">5</div>
                  <!-- - animated number -->
                  <div class="counter__label">Years in Blockchain</div>
                </div>
              </div>
              <!-- - counter -->
              <!-- counter -->
              <div class="aside-counters__wrapper-item col-lg-3 col-sm-6">
                <div class="counter js-counter" data-counter-start="0" data-counter-target="5" data-counter-duration="4">
                  <!-- animated number -->
                  <div class="counter__number js-counter__number">5</div>
                  <!-- - animated number -->
                  <div class="counter__label">Years in Cutting Edge Techs</div>
                </div>
              </div>
              <!-- - counter -->
            </div>
          </div>
        </aside>
        <!-- - aside COUNTERS -->
        <br> <br>
        <!-- section TESTIMONIALS -->
        <section class="section section-testimonials bg-white pt-medium pb-medium pb-medium">
          <div class="swiper-container slider slider-testimonials js-slider-testimonials" data-speed="2000">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="container figure-testimonial text-center">
                  <div class="row justify-content-center">
                    <!-- content -->
                    <div class="col-lg-8">
                      <div class="figure-testimonial__sign mx-auto"><img src="img/general/quote-black.svg" alt="alt" /></div>
                      <div class="slider-testimonials__text split-text js-split-text" data-split-text-type="lines" data-split-text-set="lines">
                        <blockquote class="figure-testimonial__text">Stay Hunger.<br>Stay Foolish.</blockquote>
                        <div class="figure-testimonial__author small-caps mt-2">Steve Jobs</div>
                      </div>
                    </div>
                    <!-- - content -->
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="container figure-testimonial text-center">
                  <div class="row justify-content-center">
                    <!-- content -->
                    <div class="col-lg-8">
                      <div class="figure-testimonial__sign mx-auto"><img src="img/general/quote-black.svg" alt="alt" /></div>
                      <div class="slider-testimonials__text split-text js-split-text" data-split-text-type="lines" data-split-text-set="lines">
                        <blockquote class="figure-testimonial__text">If I have 1,000 ideas and only one turns out to be good, I am satisfied.</blockquote>
                        <div class="figure-testimonial__author small-caps mt-2">Alfred Nobel</div>
                      </div>
                    </div>
                    <!-- - content -->
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="container figure-testimonial text-center">
                  <div class="row justify-content-center">
                    <!-- content -->
                    <div class="col-lg-8">
                      <div class="figure-testimonial__sign mx-auto"><img src="img/general/quote-black.svg" alt="alt" /></div>
                      <div class="slider-testimonials__text split-text js-split-text" data-split-text-type="lines" data-split-text-set="lines">
                        <blockquote class="figure-testimonial__text">If you always do what you always did, you will always get what you always got.</blockquote>
                        <div class="figure-testimonial__author small-caps mt-2">Albert Einstein</div>
                      </div>
                    </div>
                    <!-- - content -->
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="container figure-testimonial text-center">
                  <div class="row justify-content-center">
                    <!-- content -->
                    <div class="col-lg-8">
                      <div class="figure-testimonial__sign mx-auto"><img src="img/general/quote-black.svg" alt="alt" /></div>
                      <div class="slider-testimonials__text split-text js-split-text" data-split-text-type="lines" data-split-text-set="lines">
                        <blockquote class="figure-testimonial__text">Never before in history has innovation offered promise of so much to so many in so short a time.</blockquote>
                        <div class="figure-testimonial__author small-caps mt-2">Bill Gates</div>
                      </div>
                    </div>
                    <!-- - content -->
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="container figure-testimonial text-center">
                  <div class="row justify-content-center">
                    <!-- content -->
                    <div class="col-lg-8">
                      <div class="figure-testimonial__sign mx-auto"><img src="img/general/quote-black.svg" alt="alt" /></div>
                      <div class="slider-testimonials__text split-text js-split-text" data-split-text-type="lines" data-split-text-set="lines">
                        <blockquote class="figure-testimonial__text">Creativity is thinking up new things. <br>Innovation is doing new things.</blockquote>
                        <div class="figure-testimonial__author small-caps mt-2">Theodore Levitt</div>
                      </div>
                    </div>
                    <!-- - content -->
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="container figure-testimonial text-center">
                  <div class="row justify-content-center">
                    <!-- content -->
                    <div class="col-lg-8">
                      <div class="figure-testimonial__sign mx-auto"><img src="img/general/quote-black.svg" alt="alt" /></div>
                      <div class="slider-testimonials__text split-text js-split-text" data-split-text-type="lines" data-split-text-set="lines">
                        <blockquote class="figure-testimonial__text">Innovation survives only when people believe in their own ideas.</blockquote>
                        <div class="figure-testimonial__author small-caps mt-2">Levo League</div>
                      </div>
                    </div>
                    <!-- - content -->
                  </div>
                </div>
              </div>
            </div>
            <!-- slider FOOTER -->
            <div class="container slider-testimonials__footer mt-xsmall">
              <div class="row justify-content-center align-items-center slider-testimonials__row no-gutters">
                <!-- slider DOTS -->
                <div class="col-auto">
                  <div class="slider__dots js-slider__dots">
                    <div class="slider__dot slider__dot_active">
                      <svg class="svg-circle" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <circle class="circle" cx="30" cy="30" r="29" fill="none"></circle>
                      </svg>
                    </div>
                    <div class="slider__dot">
                      <svg class="svg-circle" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <circle class="circle" cx="30" cy="30" r="29" fill="none"></circle>
                      </svg>
                    </div>
                    <div class="slider__dot">
                      <svg class="svg-circle" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <circle class="circle" cx="30" cy="30" r="29" fill="none"></circle>
                      </svg>
                    </div>
                    <div class="slider__dot">
                      <svg class="svg-circle" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <circle class="circle" cx="30" cy="30" r="29" fill="none"></circle>
                      </svg>
                    </div>
                  </div>
                </div>
                <!-- - slider DOTS -->
              </div>
            </div>
            <!-- - slider FOOTER -->
            <!-- slider ARROWS -->
            <div class="slider-testimonials__arrows container-fluid">
              <div class="slider__arrow slider__arrow_left js-slider__arrow-prev">
                <div class="arrow arrow-left js-arrow" data-arts-cursor="data-arts-cursor" data-arts-cursor-hide-native="true" data-arts-cursor-scale="0" data-arts-cursor-magnetic="data-arts-cursor-magnetic">
                  <svg class="svg-circle" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <circle class="circle" cx="30" cy="30" r="29" fill="none"></circle>
                  </svg>
                  <div class="arrow__pointer arrow-left__pointer"></div>
                  <div class="arrow__triangle"></div>
                </div>
              </div>
              <div class="slider__arrow slider__arrow_right js-slider__arrow-next">
                <div class="arrow arrow-right js-arrow" data-arts-cursor="data-arts-cursor" data-arts-cursor-hide-native="true" data-arts-cursor-scale="0" data-arts-cursor-magnetic="data-arts-cursor-magnetic">
                  <svg class="svg-circle" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <circle class="circle" cx="30" cy="30" r="29" fill="none"></circle>
                  </svg>
                  <div class="arrow__pointer arrow-right__pointer"></div>
                  <div class="arrow__triangle"></div>
                </div>
              </div>
            </div>
            <!-- - slider ARROWS -->
          </div>
        </section>
        <!-- - section TESTIMONIALS -->
      </main>
      <!-- PAGE FOOTER -->
      <footer class="footer container-fluid" id="page-footer" data-arts-theme-text="dark" data-arts-footer-logo="primary">

        <!-- widgets bottom area -->
        <div class="footer__area footer__area-border-top pt-sm-3 pb-sm-1 pt-2 pb-0">
          <div class="row align-items-center">
            <!-- widget LOGO -->
            <div class="col-lg-3 footer__column text-center text-lg-left order-lg-1">
              <section class="widget widget_rhye_logo">
              </section>
            </div>
            <!-- - widget LOGO -->
            <!-- widget SOCIAL -->
            <div class="col-lg-3 footer__column text-center text-lg-right order-lg-3">
              <section class="widget widget_rhye_social">
                <!-- content -->
                <ul class="social">
                  <li class="social__item"><a class="fa fa-facebook-f" href="https://www.facebook.com/iyawat" target="_blank"></a></li>
                  <li class="social__item"><a class="fa fa-twitter" href="https://twitter.com/iyawat" target="_blank"></a></li>
                  <li class="social__item"><a class="fa fa-linkedin" href="https://th.linkedin.com/in/iyawat-kongmalai-b7434729" target="_blank"></a></li>
                  <li class="social__item"><a class="fa fa-medium" href="https://medium.com/@iyawatkongmalai" target="_blank"></a></li>
                </ul>
                <!-- - content -->
              </section>
            </div>
            <!-- - widget SOCIAL -->
            <!-- widget TEXT -->
            <div class="col-lg-6 footer__column text-center text-lg-center order-lg-2">
              <section class="widget widget_text">
                <!-- content -->
                <div class="textwidget">
                  <p><small>© 2020 Iyawat Kongmalai</small></p>
                </div>
                <!-- - content -->
              </section>
            </div>
            <!-- - widget TEXT -->
          </div>
        </div>
        <!-- - widgetst bottom area -->
      </footer>
      <!-- - PAGE FOOTER -->
    </div>
    <!-- - PAGE MAIN -->
  </div>

</body>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar'

export default {
  name: 'About',
  components: {
    NavBar
  }
}
</script>
