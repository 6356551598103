<template>
<body>
  <div data-barba="wrapper">

    <NavBar />

    <!-- PAGE MAIN -->
    <div class="js-smooth-scroll bg-dark-1" id="page-wrapper" data-barba="container">
      <main class="page-wrapper__content">
        <!-- section MASTHEAD -->
        <section class="section section-masthead d-none" data-background-color="var(--color-light-4)"></section>
        <!-- - section MASTHEAD -->
        <!-- section PROJECTS SLIDER FULLSCREEN -->
        <section class="section section-fullheight section-projects section-projects-slider bg-light-4 overflow" data-arts-theme-text="light" data-arts-os-animation>
          <div class="section-fullheight__inner section-fullheight__inner_mobile">
            <div class="slider slider-fullscreen-projects js-slider-fullscreen-projects js-slider">
              <!-- slider CONTENT -->
              <div class="slider-fullscreen-projects__content slider-fullscreen-projects__content_reduced-sides swiper-container js-slider-fullscreen-projects__content">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <router-link class="d-inline-block js-change-text-hover" to="/Work" data-pjax-link="fullscreenSlider">
                      <div class="small-caps slider__subheading js-split-text split-text-title mb-1" data-split-text-type="lines, words, chars" data-split-text-set="chars">Technology</div>
                      <h2 class="h2 slider__heading js-split-text split-text-title" data-split-text-type="lines, words, chars" data-split-text-set="chars">Blockchain Developer</h2>
                      <div class="slider__text js-split-text split-text-title mb-1" data-split-text-type="lines" data-split-text-set="lines">
                        <p>Focus on blockchain technologies like Bitcoin, Ethereum, Libra, NEAR and other blockchain networks. Developing application platform using blockchain technologies.</p>
                      </div>
                      <div class="slider__wrapper-button mt-2"><a class="button button_black button_solid" data-hover="Read More" href="project-details-1-fullscreen-center.html"><span class="button__label-hover">Read More</span></a>
                      </div>

                    </router-link>
                  </div>
                  <div class="swiper-slide">
                    <router-link class="d-inline-block js-change-text-hover" to="project-details-8-halfscreen-right-left.html" data-pjax-link="fullscreenSlider">
                      <div class="small-caps slider__subheading js-split-text split-text-title mb-1" data-split-text-type="lines, words, chars" data-split-text-set="chars">Application Development</div>
                      <h2 class="h2 slider__heading js-split-text split-text-title" data-split-text-type="lines, words, chars" data-split-text-set="chars">Full-Stack Developer</h2>
                      <div class="slider__text js-split-text split-text-title mb-1" data-split-text-type="lines" data-split-text-set="lines">
                        <p>Frontend skills: VUE.js, HTML5, CSS3, Javascript </p>
                        <p>Backend skills: Nodejs, Python</p>
                        <p>Database: MySQL, MSSQL, MongoDB</p>
                        <p>DevOp: Docker, Grafana, Zabbix, Ubuntu, Windows Server</p>
                      </div>
                      <div class="slider__wrapper-button mt-2"><a class="button button_black button_solid" data-hover="Read More" href="project-details-2-bottom-center.html"><span class="button__label-hover">Read More</span></a>
                      </div>
                    </router-link>
                  </div>
                  <div class="swiper-slide">
                    <router-link class="d-inline-block js-change-text-hover" to="project-details-11-fullscreen-center.html" data-pjax-link="fullscreenSlider">
                      <div class="small-caps slider__subheading js-split-text split-text-title mb-1" data-split-text-type="lines, words, chars" data-split-text-set="chars">Internet of Things</div>
                      <h2 class="h2 slider__heading js-split-text split-text-title" data-split-text-type="lines, words, chars" data-split-text-set="chars">IoT Developer</h2>
                      <div class="slider__text js-split-text split-text-title mb-1" data-split-text-type="lines" data-split-text-set="lines">
                        <p>Hard core IoT developer with interests in Arduino, ESP32, M5Stack, Raspberry PI, Jetson Nano</p>
                      </div>
                      <div class="slider__wrapper-button mt-2"><a class="button button_black button_solid" data-hover="Read More" href="project-details-5-bottom-container-center.html"><span class="button__label-hover">Read More</span></a>
                      </div>
                    </router-link>
                  </div>
                  <div class="swiper-slide">
                    <router-link class="d-inline-block js-change-text-hover" to="project-details-12-bottom-container-center.html" data-pjax-link="fullscreenSlider">
                      <div class="small-caps slider__subheading js-split-text split-text-title mb-1" data-split-text-type="lines, words, chars" data-split-text-set="chars">Artificial Intelligence</div>
                      <h2 class="h2 slider__heading js-split-text split-text-title" data-split-text-type="lines, words, chars" data-split-text-set="chars">AI & Chatbot</h2>
                      <div class="slider__text js-split-text split-text-title mb-1" data-split-text-type="lines" data-split-text-set="lines">
                        <p>Works in AI, Chatbot, OpenCV. Skills in Dialogflow, LINE APIs (Message API, Login, LIFF), OpenCV on IoT</p>
                      </div>
                      <div class="slider__wrapper-button mt-2"><a class="button button_black button_solid" data-hover="Read More" href="project-details-9-fullscreen-center.html"><span class="button__label-hover">Read More</span></a>
                      </div>
                    </router-link>
                  </div>
                  <div class="swiper-slide">
                    <router-link class="d-inline-block js-change-text-hover" to="project-details-12-bottom-container-center.html" data-pjax-link="fullscreenSlider">
                      <div class="small-caps slider__subheading js-split-text split-text-title mb-1" data-split-text-type="lines, words, chars" data-split-text-set="chars">Runners & Travelers</div>
                      <h2 class="h2 slider__heading js-split-text split-text-title" data-split-text-type="lines, words, chars" data-split-text-set="chars">Travels and Foods</h2>
                      <div class="slider__text js-split-text split-text-title mb-1" data-split-text-type="lines" data-split-text-set="lines">
                        <p>Sharing traveling experiences and food blogging</p>
                      </div>
                      <div class="slider__wrapper-button mt-2"><a class="button button_black button_solid" data-hover="Read More" href="project-details-11-fullscreen-center.html"><span class="button__label-hover">Read More</span></a>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
              <!-- - slider CONTENT -->
              <!-- slider IMAGES -->
              <div class="slider-fullscreen-projects__images swiper-container js-slider-fullscreen-projects__images" data-mousewheel-enabled="data-mousewheel-enabled" data-direction="vertical" data-transition-effect="enterLeave" data-speed="1200" data-counter-style="roman" data-autoplay-enabled="true" data-autoplay-delay="6000" data-touch-ratio="1.5" data-drag-mouse="true" data-drag-cursor="true" data-drag-class="slider-fullscreen-projects__images_scale-up">
                <div class="swiper-wrapper">
                  <div class="swiper-slide overflow">
                    <div class="slider__images-slide-inner js-transition-img overflow" data-swiper-parallax="90%" data-swiper-parallax-opacity="0">
                      <div class="slider__bg swiper-lazy js-transition-img__transformed-el" data-background="img/assets/projects/blockchain-bg.jpg"></div>
                    </div>
                  </div>
                  <div class="swiper-slide overflow">
                    <div class="slider__images-slide-inner js-transition-img overflow" data-swiper-parallax="90%" data-swiper-parallax-opacity="0">
                      <div class="slider__bg swiper-lazy js-transition-img__transformed-el" data-background="img/assets/projects/webdev-bg.jpg"></div>
                    </div>
                  </div>
                  <div class="swiper-slide overflow">
                    <div class="slider__images-slide-inner js-transition-img overflow" data-swiper-parallax="90%" data-swiper-parallax-opacity="0">
                      <div class="slider__bg swiper-lazy js-transition-img__transformed-el" data-background="img/assets/projects/iot-bg.jpg"></div>
                    </div>
                  </div>
                  <div class="swiper-slide overflow">
                    <div class="slider__images-slide-inner js-transition-img overflow" data-swiper-parallax="90%" data-swiper-parallax-opacity="0">
                      <div class="slider__bg swiper-lazy js-transition-img__transformed-el" data-background="img/assets/projects/ai-bg.jpg"></div>
                    </div>
                  </div>
                  <div class="swiper-slide overflow">
                    <div class="slider__images-slide-inner js-transition-img overflow" data-swiper-parallax="90%" data-swiper-parallax-opacity="0">
                      <div class="slider__bg swiper-lazy js-transition-img__transformed-el" data-background="img/assets/projects/travel-bg.jpg"></div>
                    </div>
                  </div>
                </div>
                <!-- overlay-->
                <div class="slider__overlay overlay overlay_light"></div>
                <!-- - overlay-->
              </div>
              <!-- - slider IMAGES -->
              <!-- slider COUNTER -->
              <div class="slider__wrapper-counter slider-fullscreen-projects__counter">
                <div class="slider__counter slider__counter_current">
                  <div class="js-slider-fullscreen-projects__counter-current swiper-container">
                    <div class="swiper-wrapper"></div>
                  </div>
                </div>
                <div class="slider__counter-divider slider-fullscreen__counter-divider"></div>
                <div class="slider__counter slider__counter_total js-slider-fullscreen-projects__counter-total"></div>
              </div>
              <!-- - slider COUNTER -->
              <!-- slider ARROWS -->
              <div class="slider__wrapper-arrows slider__wrapper-arrows_right">
                <div class="slider__arrow js-slider__arrow-prev">
                  <div class="arrow arrow-left js-arrow arrow-up" data-arts-cursor="data-arts-cursor" data-arts-cursor-hide-native="true" data-arts-cursor-scale="0" data-arts-cursor-magnetic="data-arts-cursor-magnetic">
                    <svg class="svg-circle" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <circle class="circle" cx="30" cy="30" r="29" fill="none"></circle>
                    </svg>
                    <div class="arrow__pointer arrow-left__pointer"></div>
                    <div class="arrow__triangle"></div>
                  </div>
                </div>
                <div class="slider__arrow js-slider__arrow-next">
                  <div class="arrow arrow-right js-arrow arrow-down" data-arts-cursor="data-arts-cursor" data-arts-cursor-hide-native="true" data-arts-cursor-scale="0" data-arts-cursor-magnetic="data-arts-cursor-magnetic">
                    <svg class="svg-circle" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <circle class="circle" cx="30" cy="30" r="29" fill="none"></circle>
                    </svg>
                    <div class="arrow__pointer arrow-right__pointer"></div>
                    <div class="arrow__triangle"></div>
                  </div>
                </div>
              </div>
              <!-- - slider ARROWS -->
            </div>
          </div>
        </section>
        <!-- - section PROJECTS SLIDER FULLSCREEN -->
      </main>
      <!-- PAGE FOOTER -->
      <!-- - PAGE FOOTER -->
    </div>
    <!-- - PAGE MAIN -->

  </div>

</body>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar'

export default {
  name: 'Home',
  components: {
    NavBar
  }
}
</script>

<style scoped>
body {
  overflow: hidden;
}
</style>

<style lang="scss" scoped>
.wrapper,
.js-smooth-scroll {
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>
