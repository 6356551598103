import GhostContentAPI from "@tryghost/content-api";

// Create API instance with site credentials

// const api = new GhostContentAPI({
//   url: 'https://blog.s-sofin.com',
//   key: 'ac8264ace10baa5068fca9cd8c',
//   version: "v2"
// });

const api = new GhostContentAPI({
  url: 'https://blog.iyawat.com',
  key: 'dcece878269809420bcc202e06',
  version: "v2"
})

export async function getPosts() {
  return await api.posts
    .browse({
      limit: "all",
      include: 'tags,authors'
    })
    .catch(err => {
      console.error(err);
    });
}

export async function getSinglePost(postSlug) {
  return await api.posts
    .read({
      slug: postSlug
    })
    .catch(err => {
      console.error(err);
    });
}


export async function getPostbyTag(tag) {
  return await api.posts
    .browse({
      filter: tag,
      include: 'tags,authors'
    })
    .catch(err => {
      console.error(err);
    });
}

export async function getPostbyAuthor(author) {
  return await api.posts
    .browse({
      filter: `authors:${author}`,
      include: 'tags,authors'
    })
    .catch(err => {
      console.error(err);
    });
}

export async function getAllTags() {
  return await api.tags
    .browse({
    })
    .catch(err => {
      console.error(err);
    });
}