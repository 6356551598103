<template>
<body>
  <div data-barba="wrapper">

    <NavBar />

    <!-- PAGE MAIN -->
    <div class="js-smooth-scroll bg-light-3" id="page-wrapper" data-barba="container">
      <main class="page-wrapper__content">
        <!-- section MASTHEAD -->
        <section class="section section-masthead section-masthead_fixed section-fullheight text-center bg-light-1" data-arts-theme-text="dark" data-arts-scroll-fixed="data-arts-scroll-fixed" data-arts-os-animation="data-arts-os-animation" data-background-color="var(--color-light-4)">
          <div class="section-masthead__inner section-fullheight__inner container-fluid">
            <header class="row section-masthead__header justify-content-center">
              <div class="col">
                <figure class="post-full-image">
                  <img :srcset="post.feature_image" sizes="(max-width: 800px) 400px,
                            (max-width: 1170px) 1170px,
                            2000px" :src="post.feature_image" />
                </figure>
                <div class="section-masthead__meta small-caps mt-0 mb-2">
                  <div class="section-masthead__meta-item split-text js-split-text" data-split-text-type="lines" data-split-text-set="lines"><a href="#">{{ post.updated_at | formatDate }}</a></div>
                </div>
                <div class="w-100"></div>
                <div class="section-masthead__heading split-text-pd js-split-text" data-split-text-type="lines,words" data-split-text-set="words">
                  <h1 class="h1 mt-0 mb-0" style="color: #FFF;">{{post.title}}</h1>
                </div>
                <div class="w-100"></div>
                <div class="section__headline mt-2 mx-auto"></div>
              </div>
            </header>
          </div>
          <div class="section-masthead__wrapper-scroll-down section-masthead__wrapper-scroll-down_center">
            <div class="circle-button js-circle-button">
              <!-- curved label -->
              <div class="circle-button__outer">
                <div class="circle-button__wrapper-label">
                  <div class="circle-button__label small-caps">Start Reading</div>
                </div>
              </div>
              <!-- - curved label -->
              <!-- geometry wrapper -->
              <div class="circle-button__inner">
                <div class="circle-button__circle" data-arts-scroll-down="data-arts-scroll-down" data-arts-cursor="data-arts-cursor" data-arts-cursor-hide-native="true" data-arts-cursor-scale="0">
                  <svg class="svg-circle" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <circle class="circle" cx="30" cy="30" r="29" fill="none"></circle>
                  </svg>
                </div>
                <!-- browsers with touch support -->
                <div class="circle-button__icon circle-button__icon-touch">
                  <svg enable-background="new 0 0 64 64" height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg">
                    <path d="m32 8c-1.104 0-2 .896-2 2v39.899l-14.552-15.278c-.761-.799-2.026-.832-2.828-.069-.8.762-.831 2.027-.069 2.827l16.62 17.449c.756.756 1.76 1.172 2.829 1.172 1.068 0 2.073-.416 2.862-1.207l16.586-17.414c.762-.8.73-2.065-.069-2.827-.799-.763-2.065-.731-2.827.069l-14.552 15.342v-39.963c0-1.104-.896-2-2-2z"></path>
                  </svg>
                </div>
                <!-- - browsers with touch support -->
                <!-- - browsers without touch support -->
                <div class="circle-button__icon circle-button__icon-mouse">
                  <svg class="svg-mouse" width="23px" height="35px" viewBox="0 0 23 35" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <!-- border -->
                    <path class="svg-mouse__border" d="M11.5,0 C5.15875132,0 0,5.23135343 0,11.6610111 L0,23.3396542 C0,29.7691456 5.15875132,35 11.5,35 C17.8412487,35 23,29.7693119 23,23.3396542 L23,11.6610111 C23,5.23135343 17.8410847,0 11.5,0 Z M21.7222404,23.3396542 C21.7222404,29.0545544 17.136538,33.7037222 11.5,33.7037222 C5.86346203,33.7037222 1.27775956,29.0545544 1.27775956,23.3396542 L1.27775956,11.6610111 C1.27775956,5.946111 5.86346203,1.29627781 11.5,1.29627781 C17.136538,1.29627781 21.7222404,5.94594466 21.7222404,11.6610111 L21.7222404,23.3396542 Z"></path>
                    <!-- - border -->
                    <!-- wheel -->
                    <path class="svg-mouse__wheel" d="M11.5,4 C11.2238902,4 11,4.28321727 11,4.63321727 L11,10.3667827 C11,10.7167827 11.2238902,11 11.5,11 C11.7761098,11 12,10.7167827 12,10.3667827 L12,4.63321727 C11.9998717,4.28321727 11.7761098,4 11.5,4 Z"></path>
                    <!-- - wheel -->
                  </svg>
                </div>
                <!-- - browsers without touch support -->
              </div>
              <!-- - geometry wrapper -->
            </div>
          </div>
          <div class="section-image section-masthead__background section-masthead__background_fullscreen">
            <div class="section-image__wrapper">
              <div :data-src="post.feature_image"></div>
            </div>
            <!-- overlay-->
            <div class="overlay overlay_dark section-masthead__overlay"></div>
            <!-- - overlay -->
          </div>
        </section>
        <!-- - section MASTHEAD -->
        <!-- SINGLE POST -->
        <section class="section section-blog pb-medium">
          <div class="container bg-white pt-small pb-small">
            <div class="row justify-content-center">
              <!-- post content -->
              <div class="col-lg-10 section-blog__post">
                <article class="post" v-html="post.html">

                  <!-- - post comments -->
                </article>
              </div>
              <!-- - post content -->
            </div>
          </div>
        </section>
        <!-- - SINGLE POST -->
      </main>

      <!-- PAGE FOOTER -->
      <footer class="footer container-fluid" id="page-footer" data-arts-theme-text="dark" data-arts-footer-logo="primary">

        <!-- widgets bottom area -->
        <div class="footer__area footer__area-border-top pt-sm-3 pb-sm-1 pt-2 pb-0">
          <div class="row align-items-center">
            <!-- widget LOGO -->
            <div class="col-lg-3 footer__column text-center text-lg-left order-lg-1">
              <section class="widget widget_rhye_logo">
              </section>
            </div>
            <!-- - widget LOGO -->
            <!-- widget SOCIAL -->
            <div class="col-lg-3 footer__column text-center text-lg-right order-lg-3">
              <section class="widget widget_rhye_social">
                <!-- content -->
                <ul class="social">
                  <li class="social__item"><a class="fa fa-facebook-f" href="https://www.facebook.com/iyawat" target="_blank"></a></li>
                  <li class="social__item"><a class="fa fa-twitter" href="https://twitter.com/iyawat" target="_blank"></a></li>
                  <li class="social__item"><a class="fa fa-linkedin" href="https://th.linkedin.com/in/iyawat-kongmalai-b7434729" target="_blank"></a></li>
                  <li class="social__item"><a class="fa fa-medium" href="https://medium.com/@iyawatkongmalai" target="_blank"></a></li>
                </ul>
                <!-- - content -->
              </section>
            </div>
            <!-- - widget SOCIAL -->
            <!-- widget TEXT -->
            <div class="col-lg-6 footer__column text-center text-lg-center order-lg-2">
              <section class="widget widget_text">
                <!-- content -->
                <div class="textwidget">
                  <p><small>© 2020 Iyawat Kongmalai</small></p>
                </div>
                <!-- - content -->
              </section>
            </div>
            <!-- - widget TEXT -->
          </div>
        </div>
        <!-- - widgetst bottom area -->
      </footer>
      <!-- - PAGE FOOTER -->

    </div>
    <!-- - PAGE MAIN -->

  </div>

</body>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar'
import {
  getSinglePost
} from '@/api/posts';

export default {
  name: 'Blog',
  components: {
    NavBar
  },
  data: () => ({
    post: []
  }),
  mounted() {
    this.asyncData()
  },
  methods: {
    async asyncData() {
      getSinglePost(this.$route.params.slug).then(result => {
        console.log(result)
        this.post = result
      })
    }
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text !== null) {
        return text.substring(0, length) + suffix
      }
    },
    formatDate: function (date) {
      return date.replace('T', ', ').replace('.000', '').replace('+07:00', '')
    }
  }
}
</script>

<style lang="scss">
.medium-zoom-image.medium-zoom-image--opened,
.medium-zoom-image.medium-zoom-image--hidden {
  z-index: 100 !important;
  opacity: 1 !important;
}
</style>
