<template>
<!-- PAGE HEADER -->
<header class="header header_menu-right header_fixed container-fluid js-header-sticky" id="page-header" data-arts-theme-text="dark" data-arts-header-sticky-theme="bg-white" data-arts-header-logo="primary" data-arts-header-sticky-logo="primary" data-arts-header-overlay-theme="light" data-arts-header-overlay-background="#ffffff">
  <!-- top bar -->
  <div class="header__container header__controls">
    <div class="row justify-content-between align-items-center">
      <!-- logo -->
      <div class="col-auto header__col header__col-left">
        <router-link class="logo" to="/">
          <div class="logo__wrapper-img">
            <h3 class="logo-title"> IYAWAT </h3>
            <h5 class="logo-subtitle"> KONGMALAI </h5>
          </div>
        </router-link>
      </div>
      <!-- - logo -->
      <!-- burger icon -->
      <div class="col-auto header__col">
        <div class="header__burger" id="js-burger" data-arts-cursor="data-arts-cursor" data-arts-cursor-scale="1.7" data-arts-cursor-magnetic="data-arts-cursor-magnetic" data-arts-cursor-hide-native="true">
          <div class="header__burger-line"></div>
          <div class="header__burger-line"></div>
          <div class="header__burger-line"></div>
        </div>
      </div>
      <!-- - burger icon -->
      <!-- "back" button for submenu nav -->
      <div class="header__overlay-menu-back" id="js-submenu-back">
        <div class="arrow arrow-left js-arrow arrow_mini" data-arts-cursor="data-arts-cursor" data-arts-cursor-hide-native="true" data-arts-cursor-scale="0" data-arts-cursor-magnetic="data-arts-cursor-magnetic">
          <svg class="svg-circle" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <circle class="circle" cx="30" cy="30" r="29" fill="none"></circle>
          </svg>
          <div class="arrow__pointer arrow-left__pointer"></div>
          <div class="arrow__triangle"></div>
        </div>
      </div>
      <!-- - "back" button for submenu nav -->
    </div>
  </div>
  <!-- - top bar -->
  <!-- fullscreen overlay container -->
  <div class="header__wrapper-overlay-menu container-fluid container-fluid_paddings">
    <!-- fullscreen menu -->
    <div class="header__wrapper-menu">
      <ul class="menu-overlay js-menu-overlay">

        <li class="menu-item">
          <router-link class="h2" to="/" data-pjax-link="overlayMenu">
            <div class="menu-overlay__item-wrapper split-text js-split-text" data-split-text-type="lines">Home</div>
          </router-link>
        </li>
        <li class="menu-item">
          <router-link class="h2" to="/Work" data-pjax-link="overlayMenu">
            <div class="menu-overlay__item-wrapper split-text js-split-text" data-split-text-type="lines">Works</div>
          </router-link>
        </li>
        <li class="menu-item-has-children">
          <a class="h2" href="#" data-pjax-link="overlayMenu">
            <div class="menu-overlay__item-wrapper split-text js-split-text" data-split-text-type="lines">Blog</div>
          </a>
          <ul class="sub-menu">
            <li class="menu-item">
              <router-link class="h3" to="/Blog?tag=all" data-pjax-link="overlayMenu">
                <div class="menu-overlay__item-wrapper split-text js-split-text" data-split-text-type="lines">#All</div>
              </router-link>
            </li>
            <li class="menu-item" v-for="tag in tags" :key="tag.id">
              <router-link class="h3" :to="'/Blog?tag='+tag.name" data-pjax-link="overlayMenu">
                <div class="menu-overlay__item-wrapper split-text js-split-text" data-split-text-type="lines">#{{ tag.name }}</div>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="menu-item">
          <router-link class="h2" to="/About" data-pjax-link="overlayMenu">
            <div class="menu-overlay__item-wrapper split-text js-split-text" data-split-text-type="lines">About</div>
          </router-link>
        </li>
      </ul>
    </div>
    <!-- - fullscreen menu -->
    <!-- fullscreen widgets -->
    <div class="header__wrapper-overlay-widgets">
      <div class="row">
        <!-- widget TEXT -->
        <div class="col-lg-4 header__widget">
          <div class="header__widget-title small-caps js-split-text split-text" data-split-text-type="lines"></div>
          <div class="header__widget-content js-split-text split-text" data-split-text-type="lines">

          </div>
        </div>
        <!-- - widget TEXT -->
        <!-- widget TEXT -->
        <div class="col-lg-4 header__widget">
          <div class="header__widget-title js-split-text split-text" data-split-text-type="lines">Contacts</div>
          <div class="header__widget-content js-split-text split-text" data-split-text-type="lines">
            <p><a href="mailto:iyawat@gmail.com">iyawat@gmail.com</a><br /><a href="tel:66818169992">+66 81-816-9992</a><br />
            </p>
          </div>
        </div>
        <!-- - widget TEXT -->
        <!-- widget TEXT -->
        <div class="col-lg-4 header__widget">
          <div class="header__widget-title js-split-text split-text" data-split-text-type="lines">Contacts</div>
          <div class="header__widget-content js-split-text split-text" data-split-text-type="lines">
            <ul class="social">
              <li class="social__item"><a class="fa fa-facebook-f" href="https://www.facebook.com/iyawat" target="_blank"></a></li>
              <li class="social__item"><a class="fa fa-twitter" href="https://twitter.com/iyawat" target="_blank"></a></li>
              <li class="social__item"><a class="fa fa-linkedin" href="https://th.linkedin.com/in/iyawat-kongmalai-b7434729" target="_blank"></a></li>
              <li class="social__item"><a class="fa fa-medium" href="https://medium.com/@iyawatkongmalai" target="_blank"></a></li>
            </ul>
          </div>
        </div>
        <!-- - widget TEXT -->
      </div>
    </div>
    <!-- - fullscreen widgets -->
  </div>
  <!-- - fullscreen overlay container -->
</header>
<!-- - PAGE HEADER -->
</template>

<script>
import {
  getAllTags
} from '@/api/posts'

export default {
  name: 'NavBar',
  data: () => ({
    tags: []
  }),
  mounted() {
    this.asyncData()
    if (localStorage.getItem('reloaded')) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem('reloaded');
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem('reloaded', '1');
      location.reload();
    }
  },
  methods: {
    async asyncData() {
      getAllTags().then(result => {
        // console.log(result)
        this.tags = result
      })
    }
  },

}
</script>

<style>

</style>
