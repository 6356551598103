<template>
<body>
  <div data-barba="wrapper">

    <NavBar />

    <!-- PAGE MAIN -->
    <div class="js-smooth-scroll bg-light-3" id="page-wrapper" data-barba="container">
      <main class="page-wrapper__content">
        <!-- section MASTHEAD -->
        <section class="section section-masthead d-none" data-background-color="var(--color-light-4)"></section>
        <!-- - section MASTHEAD -->
        <!-- section GRID PROJECTS 3 COLUMNS-->
        <section class="section section-grid section-content overflow bg-light-3 pt-large pb-medium" data-arts-os-animation="data-arts-os-animation" data-grid-columns="3" data-grid-tablet="2" data-grid-mobile="1" data-arts-theme-text="light">
          <div class="container-fluid">
            <div class="row justify-content-between align-items-center section pb-medium">
              <div class="col-12 col-lg-auto">
                <h1 class="h1 mt-0 mb-0">Works</h1>
                <div class="w-100"></div>
                <div class="section__headline mt-2 mr-auto"></div>
              </div>
            </div>
            <div class="grid grid_fluid-5 js-grid">
              <div class="grid__item grid__item_desktop-4 grid__item_tablet-6 grid__item_mobile-12 grid__item_fluid-5 grid__item_fluid-5-fancy grid__sizer js-grid__sizer"></div>

              <div class="grid__item grid__item_desktop-4 grid__item_tablet-6 grid__item_mobile-12 grid__item_fluid-5 grid__item_fluid-5-fancy js-grid__item category-portraits">
                <div class="section-grid__item"><a class="figure-project hover-zoom js-change-text-hover">
                    <div class="hover-zoom__inner">
                      <div class="hover-zoom__zoom">
                        <div class="figure-project__wrapper-img js-transition-img" data-arts-parallax="data-arts-parallax" data-arts-parallax-factor="0.15">
                          <div class="lazy js-transition-img__transformed-el"><img data-src="img/libracamp.jpg" src="#" alt="" width="912" height="1280" /></div>
                        </div>
                      </div>
                    </div>
                    <div class="figure-project__content pl-md-2 pt-md-2 pt-1 pl-0">
                      <h2 class="h3 figure-project__heading">Libra Camp Champion</h2>
                      <div class="split-text-subtitle js-split-text" data-split-text-type="lines,words" data-split-text-set="words">Libracamp World Champion. Won #1 from Libracamp Hackathon over competitors over the world.</div>
                      <div class="figure-project__category mt-md-1 mt-0-5">
                        <div class="change-text-hover small-caps js-change-text-hover change-text-hover_has-line text-left">
                          <!-- label by default -->
                          <div class="change-text-hover__normal js-split-text split-text js-change-text-hover__normal" data-split-text-type="lines" data-split-text-set="lines">#Libra #Blockchain</div>
                          <!-- - label by default -->
                          <!-- label on hover -->
                          <div class="change-text-hover__hover js-change-text-hover__hover">
                            <!-- hover line -->
                            <div class="change-text-hover__line js-change-text-hover__line"></div>
                            <!-- - hover line --><span class="js-split-text split-text" data-split-text-type="lines" data-split-text-set="lines">Explore Project</span>
                          </div>
                          <!-- - label on hover -->
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div class="grid__item grid__item_desktop-4 grid__item_tablet-6 grid__item_mobile-12 grid__item_fluid-5 grid__item_fluid-5-fancy js-grid__item category-portraits">
                <div class="section-grid__item"><a class="figure-project hover-zoom js-change-text-hover" href="http://nav.cx/2EteS7L" target="_blank">
                    <div class="hover-zoom__inner">
                      <div class="hover-zoom__zoom">
                        <div class="figure-project__wrapper-img js-transition-img" data-arts-parallax="data-arts-parallax" data-arts-parallax-factor="0.15">
                          <div class="lazy js-transition-img__transformed-el"><img data-src="img/libra_chatbot.jpg" src="#" alt="" width="958" height="960" /></div>
                        </div>
                      </div>
                    </div>
                    <div class="figure-project__content pl-md-2 pt-md-2 pt-1 pl-0">
                      <h2 class="h3 figure-project__heading">Libra Chatbot</h2>
                      <div class="split-text-subtitle js-split-text" data-split-text-type="lines,words" data-split-text-set="words">Libra Wallet using LINE chatbot. You can store, send, receive Libra with friends.</div>
                      <div class="figure-project__category mt-md-1 mt-0-5">
                        <div class="change-text-hover small-caps js-change-text-hover change-text-hover_has-line text-left">
                          <!-- label by default -->
                          <div class="change-text-hover__normal js-split-text split-text js-change-text-hover__normal" data-split-text-type="lines" data-split-text-set="lines">#Libra #Blockchain #Chatbot</div>
                          <!-- - label by default -->
                          <!-- label on hover -->
                          <div class="change-text-hover__hover js-change-text-hover__hover">
                            <!-- hover line -->
                            <div class="change-text-hover__line js-change-text-hover__line"></div>
                            <!-- - hover line --><span class="js-split-text split-text" data-split-text-type="lines" data-split-text-set="lines">Explore Project</span>
                          </div>
                          <!-- - label on hover -->
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div class="grid__item grid__item_desktop-4 grid__item_tablet-6 grid__item_mobile-12 grid__item_fluid-5 grid__item_fluid-5-fancy js-grid__item category-ui-ux-design">
                <div class="section-grid__item"><a class="figure-project hover-zoom js-change-text-hover" href="http://nav.cx/ixpPGeF" target="_blank">
                    <div class="hover-zoom__inner">
                      <div class="hover-zoom__zoom">
                        <div class="figure-project__wrapper-img js-transition-img" data-arts-parallax="data-arts-parallax" data-arts-parallax-factor="0.15">
                          <div class="lazy js-transition-img__transformed-el"><img data-src="img/pm25_chatbot.png" src="#" alt="" width="960" height="960" /></div>
                        </div>
                      </div>
                    </div>
                    <div class="figure-project__content pl-md-2 pt-md-2 pt-1 pl-0">
                      <h2 class="h3 figure-project__heading">PM2.5 Chabot</h2>
                      <div class="split-text-subtitle js-split-text" data-split-text-type="lines,words" data-split-text-set="words">PM2.5 Chatbot using LINE Message API and Dialogflow to allow user to check on air quality and notification on.</div>
                      <div class="figure-project__category mt-md-1 mt-0-5">
                        <div class="change-text-hover small-caps js-change-text-hover change-text-hover_has-line text-left">
                          <!-- label by default -->
                          <div class="change-text-hover__normal js-split-text split-text js-change-text-hover__normal" data-split-text-type="lines" data-split-text-set="lines">#PM2.5 #Chatbot</div>
                          <!-- - label by default -->
                          <!-- label on hover -->
                          <div class="change-text-hover__hover js-change-text-hover__hover">
                            <!-- hover line -->
                            <div class="change-text-hover__line js-change-text-hover__line"></div>
                            <!-- - hover line --><span class="js-split-text split-text" data-split-text-type="lines" data-split-text-set="lines">Explore Project</span>
                          </div>
                          <!-- - label on hover -->
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div class="grid__item grid__item_desktop-4 grid__item_tablet-6 grid__item_mobile-12 grid__item_fluid-5 grid__item_fluid-5-fancy js-grid__item category-portraits">
                <div class="section-grid__item"><a class="figure-project hover-zoom js-change-text-hover" href="https://medium.com/@iyawatkongmalai/defi-wallet-part-2-1d166eed20bd" target="_blank">
                    <div class="hover-zoom__inner">
                      <div class="hover-zoom__zoom">
                        <div class="figure-project__wrapper-img js-transition-img" data-arts-parallax="data-arts-parallax" data-arts-parallax-factor="0.15">
                          <div class="lazy js-transition-img__transformed-el"><img data-src="img/defi-wallet.png" src="#" alt="" width="662" height="1352" /></div>
                        </div>
                      </div>
                    </div>
                    <div class="figure-project__content pl-md-2 pt-md-2 pt-1 pl-0">
                      <h2 class="h3 figure-project__heading">DEFI Wallet</h2>
                      <div class="split-text-subtitle js-split-text" data-split-text-type="lines,words" data-split-text-set="words">Web Application built with Vue.js and web3.js with no backend service. So you can open it anywhere, anytime, on any devices. It is supported both on browser on PC, Mobile, and LINE Front-end Framework (LIFF).</div>
                      <div class="figure-project__category mt-md-1 mt-0-5">
                        <div class="change-text-hover small-caps js-change-text-hover change-text-hover_has-line text-left">
                          <!-- label by default -->
                          <div class="change-text-hover__normal js-split-text split-text js-change-text-hover__normal" data-split-text-type="lines" data-split-text-set="lines">#DEFI #Blockchain</div>
                          <!-- - label by default -->
                          <!-- label on hover -->
                          <div class="change-text-hover__hover js-change-text-hover__hover">
                            <!-- hover line -->
                            <div class="change-text-hover__line js-change-text-hover__line"></div>
                            <!-- - hover line --><span class="js-split-text split-text" data-split-text-type="lines" data-split-text-set="lines">Explore Project</span>
                          </div>
                          <!-- - label on hover -->
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div class="grid__item grid__item_desktop-4 grid__item_tablet-6 grid__item_mobile-12 grid__item_fluid-5 grid__item_fluid-5-fancy js-grid__item category-branding">
                <div class="section-grid__item"><a class="figure-project hover-zoom js-change-text-hover" href="https://www.hackster.io/iyawat-kongmalai/m5stick-libra-watch-wallet-92a94b" target="_blank">
                    <div class="hover-zoom__inner">
                      <div class="hover-zoom__zoom">
                        <div class="figure-project__wrapper-img js-transition-img" data-arts-parallax="data-arts-parallax" data-arts-parallax-factor="0.15">
                          <div class="lazy js-transition-img__transformed-el"><img data-src="img/libra_watch.jpeg" src="#" alt="" width="281" height="211" /></div>
                        </div>
                      </div>
                    </div>
                    <div class="figure-project__content pl-md-2 pt-md-2 pt-1 pl-0">
                      <h2 class="h3 figure-project__heading">Libra Hardware Wallet</h2>
                      <div class="split-text-subtitle js-split-text" data-split-text-type="lines,words" data-split-text-set="words">This device is built on M5StickC device program with Arduino IDE. WIFI connectivity is used to connect to Kulap service for creating wallet, checking & updating balance on the device. After creating wallet, all libra account information is written to EEPROM and secure with PIN lock every time you need to access these information.</div>
                      <div class="figure-project__category mt-md-1 mt-0-5">
                        <div class="change-text-hover small-caps js-change-text-hover change-text-hover_has-line text-left">
                          <!-- label by default -->
                          <div class="change-text-hover__normal js-split-text split-text js-change-text-hover__normal" data-split-text-type="lines" data-split-text-set="lines">#Libra #Blockchain #IoT</div>
                          <!-- - label by default -->
                          <!-- label on hover -->
                          <div class="change-text-hover__hover js-change-text-hover__hover">
                            <!-- hover line -->
                            <div class="change-text-hover__line js-change-text-hover__line"></div>
                            <!-- - hover line --><span class="js-split-text split-text" data-split-text-type="lines" data-split-text-set="lines">Explore Project</span>
                          </div>
                          <!-- - label on hover -->
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div class="grid__item grid__item_desktop-4 grid__item_tablet-6 grid__item_mobile-12 grid__item_fluid-5 grid__item_fluid-5-fancy js-grid__item category-portraits">
                <div class="section-grid__item"><a class="figure-project hover-zoom js-change-text-hover" href="/maathamekhruue-ngwadfun-pm2-5-ngaay-dwy-m5stack">
                    <div class="hover-zoom__inner">
                      <div class="hover-zoom__zoom">
                        <div class="figure-project__wrapper-img js-transition-img" data-arts-parallax="data-arts-parallax" data-arts-parallax-factor="0.15">
                          <div class="lazy js-transition-img__transformed-el"><img data-src="img/pm25_device.png" src="#" alt="" width="1046" height="1046" /></div>
                        </div>
                      </div>
                    </div>
                    <div class="figure-project__content pl-md-2 pt-md-2 pt-1 pl-0">
                      <h2 class="h3 figure-project__heading">PM2.5 Monitor Device</h2>
                      <div class="split-text-subtitle js-split-text" data-split-text-type="lines,words" data-split-text-set="words">Device is built on M5Stack device program with Arduino IDE. This device can be used to monitor AQI air quality level.</div>
                      <div class="figure-project__category mt-md-1 mt-0-5">
                        <div class="change-text-hover small-caps js-change-text-hover change-text-hover_has-line text-left">
                          <!-- label by default -->
                          <div class="change-text-hover__normal js-split-text split-text js-change-text-hover__normal" data-split-text-type="lines" data-split-text-set="lines">#PM2.5 #IoT</div>
                          <!-- - label by default -->
                          <!-- label on hover -->
                          <div class="change-text-hover__hover js-change-text-hover__hover">
                            <!-- hover line -->
                            <div class="change-text-hover__line js-change-text-hover__line"></div>
                            <!-- - hover line --><span class="js-split-text split-text" data-split-text-type="lines" data-split-text-set="lines">Explore Project</span>
                          </div>
                          <!-- - label on hover -->
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div class="grid__item grid__item_desktop-4 grid__item_tablet-6 grid__item_mobile-12 grid__item_fluid-5 grid__item_fluid-5-fancy js-grid__item category-portraits">
                <div class="section-grid__item"><a class="figure-project hover-zoom js-change-text-hover" href="#">
                    <div class="hover-zoom__inner">
                      <div class="hover-zoom__zoom">
                        <div class="figure-project__wrapper-img js-transition-img" data-arts-parallax="data-arts-parallax" data-arts-parallax-factor="0.15">
                          <div class="lazy js-transition-img__transformed-el"><img data-src="img/Thaichana.png" src="#" alt="" width="1400" height="1866" /></div>
                        </div>
                      </div>
                    </div>
                    <div class="figure-project__content pl-md-2 pt-md-2 pt-1 pl-0">
                      <h2 class="h3 figure-project__heading">Thai Chana on Thaichain</h2>
                      <div class="split-text-subtitle js-split-text" data-split-text-type="lines,words" data-split-text-set="words">Build on top of the DEFI wallet, I added a function called “Check-In”. It can scan QR Code which generated on M5Stack devices. Once it get QR Code information and geolocation from GPS modules attached to the M5Stack.</div>
                      <div class="figure-project__category mt-md-1 mt-0-5">
                        <div class="change-text-hover small-caps js-change-text-hover change-text-hover_has-line text-left">
                          <!-- label by default -->
                          <div class="change-text-hover__normal js-split-text split-text js-change-text-hover__normal" data-split-text-type="lines" data-split-text-set="lines">#DEFI #Blockchain</div>
                          <!-- - label by default -->
                          <!-- label on hover -->
                          <div class="change-text-hover__hover js-change-text-hover__hover">
                            <!-- hover line -->
                            <div class="change-text-hover__line js-change-text-hover__line"></div>
                            <!-- - hover line --><span class="js-split-text split-text" data-split-text-type="lines" data-split-text-set="lines">Explore Project</span>
                          </div>
                          <!-- - label on hover -->
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div class="grid__item grid__item_desktop-4 grid__item_tablet-6 grid__item_mobile-12 grid__item_fluid-5 grid__item_fluid-5-fancy js-grid__item category-branding">
                <div class="section-grid__item"><a class="figure-project hover-zoom js-change-text-hover" href="https://medium.com/@iyawatkongmalai/line-message-api-x-woocommerce-api-x-dialogflow-50abf7d81b8f" target="_blank">
                    <div class="hover-zoom__inner">
                      <div class="hover-zoom__zoom">
                        <div class="figure-project__wrapper-img js-transition-img" data-arts-parallax="data-arts-parallax" data-arts-parallax-factor="0.15">
                          <div class="lazy js-transition-img__transformed-el"><img data-src="img/WooCommerce.png" src="#" alt="" width="476" height="1016" /></div>
                        </div>
                      </div>
                    </div>
                    <div class="figure-project__content pl-md-2 pt-md-2 pt-1 pl-0">
                      <h2 class="h3 figure-project__heading">LINE Chatbot & WooCommerce</h2>
                      <div class="split-text-subtitle js-split-text" data-split-text-type="lines,words" data-split-text-set="words">Create omni-channel commerce: POS, E-Commerce and LINE chatbot.</div>
                      <div class="figure-project__category mt-md-1 mt-0-5">
                        <div class="change-text-hover small-caps js-change-text-hover change-text-hover_has-line text-left">
                          <!-- label by default -->
                          <div class="change-text-hover__normal js-split-text split-text js-change-text-hover__normal" data-split-text-type="lines" data-split-text-set="lines">#LINE #Chatbot #E-Commerce </div>
                          <!-- - label by default -->
                          <!-- label on hover -->
                          <div class="change-text-hover__hover js-change-text-hover__hover">
                            <!-- hover line -->
                            <div class="change-text-hover__line js-change-text-hover__line"></div>
                            <!-- - hover line --><span class="js-split-text split-text" data-split-text-type="lines" data-split-text-set="lines">Explore Project</span>
                          </div>
                          <!-- - label on hover -->
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div class="grid__item grid__item_desktop-4 grid__item_tablet-6 grid__item_mobile-12 grid__item_fluid-5 grid__item_fluid-5-fancy js-grid__item category-portraits">
                <div class="section-grid__item"><a class="figure-project hover-zoom js-change-text-hover" href="https://medium.com/@iyawatkongmalai/continue-from-latest-experiment-when-you-combine-dialogflow-which-is-now-can-understand-thai-d307f0e8b940" target="_blank">
                    <div class="hover-zoom__inner">
                      <div class="hover-zoom__zoom">
                        <div class="figure-project__wrapper-img js-transition-img" data-arts-parallax="data-arts-parallax" data-arts-parallax-factor="0.15">
                          <div class="lazy js-transition-img__transformed-el"><img data-src="img/line_place.png" src="#" alt="" width="474" height="1008" /></div>
                        </div>
                      </div>
                    </div>
                    <div class="figure-project__content pl-md-2 pt-md-2 pt-1 pl-0">
                      <h2 class="h3 figure-project__heading">Dialogflow x LINE x Google</h2>
                      <div class="split-text-subtitle js-split-text" data-split-text-type="lines,words" data-split-text-set="words">Combine Dialogflow, which is now can understand Thai language (still beta but good enough) and Line payload for beautiful display and magic of Google Place API for huge amount of data.</div>
                      <div class="figure-project__category mt-md-1 mt-0-5">
                        <div class="change-text-hover small-caps js-change-text-hover change-text-hover_has-line text-left">
                          <!-- label by default -->
                          <div class="change-text-hover__normal js-split-text split-text js-change-text-hover__normal" data-split-text-type="lines" data-split-text-set="lines">#LINE #Google</div>
                          <!-- - label by default -->
                          <!-- label on hover -->
                          <div class="change-text-hover__hover js-change-text-hover__hover">
                            <!-- hover line -->
                            <div class="change-text-hover__line js-change-text-hover__line"></div>
                            <!-- - hover line --><span class="js-split-text split-text" data-split-text-type="lines" data-split-text-set="lines">Explore Project</span>
                          </div>
                          <!-- - label on hover -->
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div class="grid__item grid__item_desktop-4 grid__item_tablet-6 grid__item_mobile-12 grid__item_fluid-5 grid__item_fluid-5-fancy js-grid__item category-portraits">
                <div class="section-grid__item"><a class="figure-project hover-zoom js-change-text-hover" href="https://nearguild.kulap.io/" target="_blank">
                    <div class="hover-zoom__inner">
                      <div class="hover-zoom__zoom">
                        <div class="figure-project__wrapper-img js-transition-img" data-arts-parallax="data-arts-parallax" data-arts-parallax-factor="0.15">
                          <div class="lazy js-transition-img__transformed-el"><img data-src="img/near_guild.png" src="#" alt="" width="562" height="1216" /></div>
                        </div>
                      </div>
                    </div>
                    <div class="figure-project__content pl-md-2 pt-md-2 pt-1 pl-0">
                      <h2 class="h3 figure-project__heading">KULAP NEAR Guild</h2>
                      <div class="split-text-subtitle js-split-text" data-split-text-type="lines,words" data-split-text-set="words">Website for NEAR Blockchain Guild. Joined with KULAP team to build Thailand NEAR Blockchain Community.</div>
                      <div class="figure-project__category mt-md-1 mt-0-5">
                        <div class="change-text-hover small-caps js-change-text-hover change-text-hover_has-line text-left">
                          <!-- label by default -->
                          <div class="change-text-hover__normal js-split-text split-text js-change-text-hover__normal" data-split-text-type="lines" data-split-text-set="lines">#NEAR #Blockchain</div>
                          <!-- - label by default -->
                          <!-- label on hover -->
                          <div class="change-text-hover__hover js-change-text-hover__hover">
                            <!-- hover line -->
                            <div class="change-text-hover__line js-change-text-hover__line"></div>
                            <!-- - hover line --><span class="js-split-text split-text" data-split-text-type="lines" data-split-text-set="lines">Explore Project</span>
                          </div>
                          <!-- - label on hover -->
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

            </div>
          </div>
        </section>
        <!-- - section GRID PROJECTS 3 COLUMNS-->
      </main>

      <!-- PAGE FOOTER -->
      <footer class="footer container-fluid" id="page-footer" data-arts-theme-text="dark" data-arts-footer-logo="primary">

        <!-- widgets bottom area -->
        <div class="footer__area footer__area-border-top pt-sm-3 pb-sm-1 pt-2 pb-0">
          <div class="row align-items-center">
            <!-- widget LOGO -->
            <div class="col-lg-3 footer__column text-center text-lg-left order-lg-1">
              <section class="widget widget_rhye_logo">
              </section>
            </div>
            <!-- - widget LOGO -->
            <!-- widget SOCIAL -->
            <div class="col-lg-3 footer__column text-center text-lg-right order-lg-3">
              <section class="widget widget_rhye_social">
                <!-- content -->
                <ul class="social">
                  <li class="social__item"><a class="fa fa-facebook-f" href="https://www.facebook.com/iyawat" target="_blank"></a></li>
                  <li class="social__item"><a class="fa fa-twitter" href="https://twitter.com/iyawat" target="_blank"></a></li>
                  <li class="social__item"><a class="fa fa-linkedin" href="https://th.linkedin.com/in/iyawat-kongmalai-b7434729" target="_blank"></a></li>
                  <li class="social__item"><a class="fa fa-medium" href="https://medium.com/@iyawatkongmalai" target="_blank"></a></li>
                </ul>
                <!-- - content -->
              </section>
            </div>
            <!-- - widget SOCIAL -->
            <!-- widget TEXT -->
            <div class="col-lg-6 footer__column text-center text-lg-center order-lg-2">
              <section class="widget widget_text">
                <!-- content -->
                <div class="textwidget">
                  <p><small>© 2020 Iyawat Kongmalai</small></p>
                </div>
                <!-- - content -->
              </section>
            </div>
            <!-- - widget TEXT -->
          </div>
        </div>
        <!-- - widgetst bottom area -->
      </footer>
      <!-- - PAGE FOOTER -->
    </div>
    <!-- - PAGE MAIN -->

  </div>

</body>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar'

export default {
  name: 'Work',
  components: {
    NavBar
  }
}
</script>

<style lang="scss" scoped>
.h1,
.h2,
.h3 {
  color: #000;
}

.change-text-hover__hover {
  color: #000;
}
</style>
